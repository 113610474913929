import React, { useState } from "react";
import { Chart } from "react-google-charts";
import './App.css'; // Import the CSS file

const prizes = [  // list in clockwiseMercedes
  { label: "Hand sanitiser", sliceSize:10, probability: 1 }, 
  { label: "Mercedes Benz", sliceSize:35, probability: 0 }, 
  { label: "Thank you", sliceSize:10, probability: 1 },   
  { label: "Tesla", sliceSize:25, probability: 0 },     
  { label: "Phone stand", sliceSize:10, probability: 1 },     
  { label: "Thank you", sliceSize:10, probability: 1 },   
];

function calculatePrize(finalRotation) {
  let finalAngle = finalRotation % 360; // Get angle between 0 and 360
  let cumulativeAngle = 0;
//console.log(">>>>>   finalAngle = "+finalAngle);
  // Iterate over prizes and determine the one that matches the final rotation angle

  const reversedPrizes = [...prizes].reverse();
//  console.log("reversedPrizes = "+prizes);
  for (const prize of reversedPrizes) {
//    console.log("Prize = "+ prize.label + ", angle = "+Math.floor((prize.sliceSize/100)*360));
    cumulativeAngle += Math.floor((prize.sliceSize/100)*360);
//    console.log("cumulativeAngle = "+cumulativeAngle);
    if ( cumulativeAngle >= finalAngle ) {
//      console.log(" +++++++++++++++++++ Prize = "+prize.label);
      if ( prize.probability > 0)
        return prize.label;
      else 
        return "out of stock";
    }
  }
  return "Thank you";
}

function App() {
  const [selectedPrize, setSelectedPrize] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(0); // Store the current rotation angle
//  const [newRotation, setNewRotation] = useState(0); // Store the current rotation angle
  const [spinClass, setSpinClass] = useState(""); // Add state to control spin animation

  const data = [
    ["Prize", "size"],
    ["Hand sanitiser", 10],
    ["Mercedes Benz", 35],
    ["Thank you", 10],
    ["Tesla", 25],
    ["Phone stand", 10],
    ["Thank you", 10],
  ];

  const options = {
    pieHole: 0,
    pieSliceText: "label",
    pieStartAngle: 0,
    tooltip: { trigger: "none" },
    legend: "none",
    slices: [
      { offset: 0, color: "#0f0d58" }, // Hand sanitiser
      { offset: 0, color: "#f4b400" }, // Mercedes Benz
      { offset: 0, color: "#c0c0c0" }, // Thank you for playing
      { offset: 0, color: "#4285f4" }, // Tesla
      { offset: 0, color: "#0f9d58" }, // Phone stand
      { offset: 0, color: "#c0c0c0" }, // Thank you for playing
    ],
    backgroundColor: "transparent",
  };

  const spinWheel = () => {
    setIsSpinning(true);

    let newRo1 = 0;
    let prize = "out of stock";

    while (prize === "out of stock") {  // respin until it lands on non-"out of stock" prize
      newRo1 = Math.floor(Math.random() * 360) + 1800; // Random stop angle plus 5 full spins
      //const newRo1 = 44; // Random stop angle plus 5 full spins
//      console.log("new random = "+(newRo1));
//      console.log("new RotationAngle = "+(newRo1+rotationAngle));
    //  setNewRotation(newRo1+newRotation); 

      prize = calculatePrize(newRo1+rotationAngle);
      if ( prize !== "out of stock") {
        break;
      } else {
        console.log("out of stock!");
      }
    }

    setRotationAngle(newRo1+rotationAngle); // Store the new angle for spin
    setSpinClass("spin"); // Trigger the spin animation
    setTimeout(() => {
      //const prize = calculatePrize(newRo1+rotationAngle);
      setSelectedPrize(prize);
      setIsSpinning(false);
      setSpinClass(""); // Reset the spin animation class after spinning
    }, 3000); // 3 seconds delay for spin animation

    
  };

  return (
    <div class="centercontainer" style={{ marginTop: "10px" }}>
      <h1>Lucky Draw </h1>

      <div className="wheel-wrapper">
        <div className="needle" />
        <div
          className={`wheel ${spinClass}`}
          style={{
            transform: `rotate(${rotationAngle}deg)`, // Rotate based on the random angle
          }}
        >
          <Chart
            chartType="PieChart"
            width={"100vw"}
            height={"100vw"}
            data={data}
            options={options}
          />
        </div>
      </div>


      <button
        class="button-19" 
        role="button"
        onClick={spinWheel}
        disabled={isSpinning}
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          fontSize: "18px",
//          cursor: isSpinning ? "not-allowed" : "pointer",
          margin:"0 auto",
          display: "block",
        }}
      > Spin
        {//isSpinning ? "Spinning..." : "Spin the Wheel"
        }
      </button>


      {selectedPrize && !isSpinning && (
        <h2 style={{ color: "#333" }}>
          {selectedPrize!=="Thank you"?
          `Congratz, you won a ${selectedPrize}!`: "Thank you for playing, better luck next time!" 
          }
        </h2>
      )}
      
    </div>
  );
}

export default App;

